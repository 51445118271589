'use client';

import { useEffect, type PropsWithChildren } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import useGetAuthInfo from 'app/api/auth/getAuthInfo/_helper/useGetAuthInfo';
import ClientConfig from 'lib/config/ClientConfig';
import { identifyUser } from './clientAnalytics';
const ENV_ENABLED = ClientConfig.ENVIRONMENT === 'production' || ClientConfig.ENVIRONMENT === 'staging';
if (typeof window !== 'undefined' && ClientConfig.POSTHOG_KEY && ENV_ENABLED) {
  posthog.init(ClientConfig.POSTHOG_KEY, {
    api_host: '/ingest',
    ui_host: 'https://eu.posthog.com',
    person_profiles: 'identified_only',
    disable_external_dependency_loading: true // Ensures that posthog does not load extensions lazily
  });
}
export default function AnalyticsProvider({
  children
}: Readonly<PropsWithChildren>) {
  //
  // Auth info
  const {
    data: authInfo
  } = useGetAuthInfo();

  // Identify user
  useEffect(() => {
    if (!authInfo?.signedIn) return;
    if (!authInfo?.userData) return;
    identifyUser(authInfo.userData);
  }, [authInfo?.signedIn, authInfo?.userData]);

  // Render
  return ENV_ENABLED ? <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="AnalyticsProvider" data-sentry-source-file="AnalyticsProvider.tsx">{children}</PostHogProvider> : children;
}