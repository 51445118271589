import { createContext } from 'react'
import { OAuthContext as IOAuthContext } from './Types'

export const DEFAULT_DATA: IOAuthContext = {
  appleLoaded: false,
  googleLoaded: false,
}

const OAuthContext = createContext(DEFAULT_DATA)
OAuthContext.displayName = 'OAuthContext'

export const Provider = OAuthContext.Provider
export const Consumer = OAuthContext.Consumer

export default OAuthContext
