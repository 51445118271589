'use client';

import { type PropsWithChildren } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import OAuthProvider from 'lib/oauth/Provider';
import AnalyticsProvider from 'lib/analytics/AnalyticsProvider';
import { getQueryClient } from 'lib/utils/getQueryClient';
function ClientProviders({
  children
}: Readonly<PropsWithChildren>) {
  const queryClient = getQueryClient();
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="ClientProviders" data-sentry-source-file="ClientProviders.tsx">
      <AnalyticsProvider data-sentry-element="AnalyticsProvider" data-sentry-source-file="ClientProviders.tsx">
        <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="ClientProviders.tsx" />
        <OAuthProvider data-sentry-element="OAuthProvider" data-sentry-source-file="ClientProviders.tsx">
          {children}
        </OAuthProvider>
      </AnalyticsProvider>
    </QueryClientProvider>;
}
export default ClientProviders;