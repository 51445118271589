import 'client-only'
import type { UserData } from 'lib/identity/Types'
import Plausible, { EventOptions, PlausibleOptions } from 'plausible-tracker'
import posthog from 'posthog-js'
import ClientConfig from 'lib/config/ClientConfig'

const ENV_ENABLED = ClientConfig.ENVIRONMENT === 'production' || ClientConfig.ENVIRONMENT === 'staging'

const plausibleClients = ClientConfig.PLAUSIBLE_DOMAINS.split(',').map((domain) => Plausible({
  domain,
  apiHost: ClientConfig.PLAUSIBLE_CLIENT_API_HOST,
  apiPath: ClientConfig.PLAUSIBLE_CLIENT_API_PATH,
}))

export function trackEvent(
  eventName: string,
  options: EventOptions,
  eventData: PlausibleOptions | undefined = undefined,
) {
  if (ENV_ENABLED) posthog.capture(eventName, options.props)

  if (!ClientConfig.ANALYTICS_ENABLED) {
    console.log('trackEvent:', eventName, options, eventData)
    return
  }

  plausibleClients.forEach((client) => {
    client.trackEvent(eventName, options, eventData)
  })
}

export function identifyUser(userData: UserData) {
  if (!ENV_ENABLED) return
  if (typeof window === 'undefined') return
  if (!userData.tid) return

  posthog.alias(userData.tid, posthog.get_distinct_id())
  posthog.identify(
    userData.tid,
    { language: userData.language, primary_identification: userData.primary_identification },
  )
}

const serverAnalytics = {
  trackEvent,
  identifyUser,
}
export default serverAnalytics
