'use client'

import type { GetResponseData } from '../route'
import axios from 'axios'

const URL = '/api/auth/getAuthInfo'

async function getAuthInfoApiCall() {
  const response = await axios.get<GetResponseData>(URL)

  return response.data
}

export default getAuthInfoApiCall
