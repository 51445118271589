import { type UseQueryOptions, useQuery } from '@tanstack/react-query'
import { type GetResponseData } from '../route'
import getOAuthConfig from './getOAuthConfig'
import queryKey from './queryKey'

function useGetOAuthConfig(options?: Partial<UseQueryOptions<GetResponseData>>) {
  return useQuery({
    ...options,
    queryKey,
    queryFn: async () => await getOAuthConfig(),
  })
}

export default useGetOAuthConfig
